<template>
    <div>
        <div class="contextBar">
            <p class="currentView">{{ this.$route.name }}</p>

            <!--Para que aparezca cuantos errores totales (Objetos)-->
            <div class="titleInfoTagsContainer" style="margin-left: 24px" v-if="this.currentStatus">
                <div class="titleInfoTags">
                    {{ Object.keys(this.currentStatus.all).length }} flows
                </div>
            </div>
        </div>
        <div v-if="this.forbiddenAccess" class="usersListContainer" style="text-align: center;">
            <p style="font-size: 10rem;">&#9940;</p><!--No entry-->
            <p style="color: red;">You don't have permission to access this area</p>
        </div>
        <div v-else-if="!this.loader" class="usersListContainer">
            <div v-if="!this.transferId">
                <h1>Flows</h1>
                <div class="responseConfigBar">
                    <div class="searchBar">
                        <i class="fi fi-rr-search"></i>
                        <input type="text" spellcheck="false" placeholder="Search flow" v-model="this.searchText"
                            @focus="$event.currentTarget.parentElement.classList.add('focus')"
                            @blur="$event.currentTarget.parentElement.classList.remove('focus')">
                    </div>
                </div>
                <div style="display: flex; gap: 4px; padding: 4px; align-items: center;">
                    <span>{{ this.selectedFlows.size }} selected</span>
                    <span class="actionButton" @click="Object.keys(this.currentStatus.all).forEach(key => this.selectedFlows.add(key))">Select all</span>
                    <span class="actionButton" @click="this.selectedFlows.clear()">Clear selection</span>
                    <span class="actionButton" @click="Object.keys(this.currentStatus.all).forEach(key => this.selectedFlows[this.selectedFlows.has(key) ? 'delete' : 'add'](key))">Invert selection</span>
                    <div style="flex-grow: 1;"></div>
                    <template v-for="key of [ 'prod', 'dev' ]" :key="key">
                        <div style="background-color: hsl(210deg 100% 80%); padding: 4px; border-radius: 8px;">
                            <div>Copy to {{ key === 'prod' ? 'development' : 'production' }}</div>
                            <div style="display: flex; gap: 4px;">
                                <span class="actionButton" @click="this.FullCopyToOther(key)">Full</span>
                                <span class="actionButton" @click="this.CodeCopyToOther(key)">Code</span>
                                <span class="actionButton" @click="this.InfoCopyToOther(key)">Info</span>
                            </div>
                        </div>
                    </template>
                    <div style="flex-grow: 1;"></div>
                    <div>
                        <label>Sort:</label>
                        <select v-model="this.sortOrder" style="padding: 4px; border-radius: 99px;">
                            <option value="+nameProd">Name production (ASC PROD)</option>
                            <option value="-nameProd">Name production (DESC PROD)</option>
                            <option value="+nameDev">Name development (ASC DEV)</option>
                            <option value="-nameDev">Name development (DESC DEV)</option>
                            <option value="-modifiedProd">Recently modified production first / Last modified (DESC PROD)</option>
                            <option value="+modifiedProd">Recently modified production last / Last modified (ASC PROD)</option>
                            <option value="-modifiedDev">Recently modified development first / Last modified (DESC DEV)</option>
                            <option value="+modifiedDev">Recently modified development last / Last modified (ASC DEV)</option>
                            <option value="+id">Id (ASC)</option>
                            <option value="-id">Id (DESC)</option>
                        </select>
                    </div>
                </div>
                <div class="flows-list">
                    <div v-for="flowData, flowId in this.allSorted" :key="flowId" class="flow" :class="{ selected: this.selectedFlows.has(flowId) }" @dblclick="this.$saveCurrentScroll(this); this.transferId = flowId;" @click="this.selectedFlows[this.selectedFlows.has(flowId) ? 'delete' : 'add'](flowId);">
                        <div style="grid-area: 1/1/2/4; display: flex; flex-direction: row;">
                            <i style="flex-grow: 1;">{{ flowId }}</i>
                            <div style="display: flex; flex-direction: row; justify-content: end;">
                                <span class="stack" :title="flowData?.prod?.status ? 'Enabled' : 'Disabled'">
                                    <span>&#128065;</span><!--Eye-->
                                    <span v-if="!flowData?.prod?.status">&#10060;</span><!--Cross-->
                                </span>
                                <span class="stack" :title="flowData.accessControl === undefined ? 'Public' : `${flowData.accessControl.usersWhitelist}-${flowData.accessControl.usersBlacklist} users, ${flowData.accessControl.groupsWhitelist}-${flowData.accessControl.groupsBlacklist} groups`">
                                    <span style="opacity: 0.75;">{{ flowData.accessControl === undefined ? '&#127760;' : '&#128272;' }}</span><!--Globe / Lock with key-->
                                    <span style="z-index: 1;">{{ flowData.accessControl === undefined ? '' : flowData.accessControl.usersWhitelist + flowData.accessControl.groupsWhitelist }}</span>
                                </span>
                                <span class="stack" :title="flowData.prod?.instructionsURL ? 'Has instructions' : 'No instructions'">
                                    <span>&#128466;</span><!--Note-->
                                    <span v-if="!flowData.prod?.instructionsURL">&#10060;</span><!--Cross-->
                                </span>
                                <span class="stack" :title="flowData.prod?.templateURL ? 'Has template' : 'No template'">
                                    <span>&#128638;</span><!--Checker board-->
                                    <span v-if="!flowData.prod?.templateURL">&#10060;</span><!--Cross-->
                                </span>
                                <span class="stack" :title="flowData.prod ? 'Available on production' : 'Not on production'">
                                    <span>&#127981;</span><!--Factory-->
                                    <span v-if="!flowData.prod">&#10060;</span><!--Cross-->
                                </span>
                                <span class="stack" :title="flowData.dev ? 'Available on development' : 'Not on development'">
                                    <span>&#127959;</span><!--Building construction-->
                                    <span v-if="!flowData.dev">&#10060;</span><!--Cross-->
                                </span>
                                <span v-if="flowData?.warning.length" style="color: red;" :title="flowData?.warning.join('\n')">&#9888;</span><!--Warning-->
                            </div>
                        </div>
                        <b style="grid-area: 2/1/3/4; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" :title="flowData?.prod?.name">{{ flowData?.prod?.name }}</b>
                        <b style="grid-area: 3/1/4/3; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" :title="flowData?.prod?.group?.name">{{ flowData?.prod?.group?.name ?? '-' }}</b>
                        <span style="grid-area: 4/1/5/2" title="Time saved ratio">TSR={{ flowData.prod?.timeSavedRatio }}</span>
                        <span style="grid-area: 4/2/5/3" title="Slans usage ratio">UR={{ flowData.prod?.usageRatio }}</span>
                        <span style="grid-area: 4/3/5/4" title="Auto repeat errors">ARE={{ flowData.prod?.autoRepeatRowErrorsLimit }}</span>
                        <span style="grid-area: 5/1/6/2" title="Last modified">{{ flowData.prod?.updateAt }}</span>
                        <i style="grid-area: 5/2/6/3" :title="flowData.prod?.compiler ? 'Compiled on version ' + flowData.prod.compiler.version : 'Not compiled'">{{ flowData.prod?.compiler ? flowData.prod.compiler.version : '-' }}</i>
                    </div>
                </div>
            </div>
            <FlowManagement v-else :flowId="this.transferId" :flowData="this.currentStatus.all[this.transferId]" v-on:back="this.transferId = undefined; this.$backToPreviousScroll(this)" @updateList="this.UpdateFlows()" />
        </div>
    </div>
</template>
<script>
import { GetFlowManagementFlows, BatchUpdateFlowManagementFlow } from '../helpers/APIconnection';
import FlowManagement from '@/components/FlowManagement'

export default {
    components: { FlowManagement },
    data: function() {
        return {
            loader: false,
            currentStatus: { dev: {}, prod: {}, all: {} },
            currentIds: [],
            transferId: undefined,
            selectedFlows: new Set(),
            sortOrder: '+nameProd',
            forbiddenAccess: false,
            searchText: '',
        }
    },
    created: function() {
        this.UpdateFlows();
    },
    computed: {
        allSorted: function() {
            const sortMethod = this.sortOrder.substring(1);
            const getNameProd = flowData => flowData.prod?.name ?? flowData.dev?.name ?? '';
            const getNameDev = flowData => flowData.dev?.name ?? flowData.prod?.name ?? '';
            const getModificationProd = flowData => new Date(flowData.prod?.updateAt ?? flowData.prod?.createAt ?? flowData.dev?.updateAt ?? flowData.dev?.createAt ?? 0);
            const getModificationDev = flowData => new Date(flowData.dev?.updateAt ?? flowData.dev?.createAt ?? flowData.prod?.updateAt ?? flowData.prod?.createAt ?? 0);
            const sorter = {
                'id': (a, b) => (b[0] ?? '').localeCompare(a[0] ?? '') < 0,
                'modifiedProd': (a, b) => getModificationProd(b[1]) < getModificationProd(a[1]),
                'modifiedDev': (a, b) => getModificationDev(b[1]) < getModificationDev(a[1]),
                'nameDev': (a, b) => getNameDev(b[1]).localeCompare(getNameDev(a[1])) < 0,
            }[sortMethod] ?? ((a, b) => getNameProd(b[1]).localeCompare(getNameProd(a[1])) < 0);
            const regex = new RegExp(this.searchText.split(/\s+/).map(e => '(?=.*?' + (RegExp.escape ? RegExp.escape(e) : e.replaceAll(/[^a-z0-9]/gi, f => '\\' + f)) + ')').join('') + '.*', 'i');
            const list = Object.entries(this.currentStatus.all).filter((data) => [ data[1].prod?.name || '', data[1].dev?.name || '', data[1].prod?.group?.name || '', data[1].dev?.group?.name || '' ].join(' ').match(regex)).sort(sorter);
            return Object.fromEntries(this.sortOrder[0] === '-' ? list.reverse() : list);
        },
    },
    methods: {
        UpdateFlows: async function() {
            this.loader = true;
            try {
                const response = await GetFlowManagementFlows();
                if (response.status === 200) {
                    this.ProcessFlows(response.data);
                }
            } catch (err) {
                if (err.response.status === 403) {
                    this.forbiddenAccess = true;
                } else {
                    console.error(err);
                }
            }
            this.loader = false;
        },
        ProcessFlows: function(data) {
            this.currentStatus = data;
            this.currentIds = [ ...new Set([ ...Object.keys(this.currentStatus.dev), ...Object.keys(this.currentStatus.prod) ]) ];
            this.currentStatus.all = Object.fromEntries([ ...new Set([ ...Object.keys(this.currentStatus.dev), ...Object.keys(this.currentStatus.prod) ]) ].map(flowId => {
                const data = { dev: this.currentStatus.dev[flowId], prod: this.currentStatus.prod[flowId], warning: [] };
                if (data.prod && data.dev) {
                    if (data.prod.name !== data.dev.name) {
                        data.warning.push('Names do not match');
                    }
                    if (data.prod.description !== data.dev.description) {
                        data.warning.push('Descriptions do not match');
                    }
                    if (data.prod.group._id !== data.dev.group._id) {
                        data.warning.push('Groups do not match');
                    }
                }
                if (data.dev) {
                    data.dev._code = 'dev.' + flowId;
                }
                if (data.prod) {
                    data.prod._code = 'prod.' + flowId;
                    if (data.prod.accessControl && !data.prod.accessControl.public) {
                        data.accessControl = {
                            usersWhitelist: data.prod.accessControl.users.whitelist.length,
                            usersBlacklist: data.prod.accessControl.users.blacklist.length,
                            groupsWhitelist: data.prod.accessControl.groups.whitelist.length,
                            groupsBlacklist: data.prod.accessControl.groups.blacklist.length,
                        };
                    } else {
                        data.accessControl = undefined;
                    }
                } else {
                    data.accessControl = { usersWhitelist: 0, usersBlacklist: 0, groupsWhitelist: 0, groupsBlacklist: 0 };
                }
                return [ flowId, data ];
            }));
        },
        CodeCopyToOther: async function(key) {
            if (key === 'prod' && confirm('Do you want to save changes on DEVELOPMENT?\nThis action cannot be undone')) {
                const batch = [ ...this.selectedFlows ].map(flowId => {
                    const flowData = this.currentStatus.all[flowId];
                    if (!flowData.prod) return undefined;
                    if (flowData.dev) {
                        flowData.dev._code = flowData.prod._code;
                    } else {
                        flowData.dev = JSON.parse(JSON.stringify(flowData.prod));
                    }
                    return { target: 'dev.' + flowId, data: flowData.dev };
                }).filter(e=>e);
                if (batch.length) {
                    await BatchUpdateFlowManagementFlow(batch).then(this.UpdateFlows);
                }
            } else if (key === 'dev' && confirm('Do you want to save changes on PRODUCTION?\nThis action cannot be undone')) {
                const batch = [ ...this.selectedFlows ].map(flowId => {
                    const flowData = this.currentStatus.all[flowId];
                    if (!flowData.dev) return undefined;
                    if (flowData.prod) {
                        flowData.prod._code = flowData.dev._code;
                    } else {
                        flowData.prod = JSON.parse(JSON.stringify(flowData.dev));
                    }
                    return { target: 'prod.' + flowId, data: flowData.prod };
                }).filter(e=>e);
                if (batch.length) {
                    await BatchUpdateFlowManagementFlow(batch).then(this.UpdateFlows);
                }
            }
        },
        InfoCopyToOther: async function(key) {
            const keysToClone = [ 'name', 'description', 'status', 'timeSavedRatio', 'usageRatio', 'autoRepeatRowErrorsLimit', 'instructionsURL', 'templateURL', 'accessControl', 'group' ];
            if (key === 'prod' && confirm('Do you want to save changes on DEVELOPMENT?\nThis action cannot be undone')) {
                const batch = [ ...this.selectedFlows ].map(flowId => {
                    const flowData = this.currentStatus.all[flowId];
                    if (!flowData.prod) return undefined;
                    if (flowData.dev) {
                        keysToClone.forEach(key => flowData.dev[key] = flowData.prod[key] ? JSON.parse(JSON.stringify(flowData.prod[key])) : flowData.prod[key]);
                    } else {
                        flowData.dev = JSON.parse(JSON.stringify(flowData.prod));
                    }
                    return { target: 'dev.' + flowId, data: flowData.dev };
                }).filter(e=>e);
                if (batch.length) {
                    await BatchUpdateFlowManagementFlow(batch).then(this.UpdateFlows);
                }
            } else if (key === 'dev' && confirm('Do you want to save changes on PRODUCTION?\nThis action cannot be undone')) {
                const batch = [ ...this.selectedFlows ].map(flowId => {
                    const flowData = this.currentStatus.all[flowId];
                    if (!flowData.dev) return undefined;
                    if (flowData.prod) {
                        keysToClone.forEach(key => flowData.prod[key] = flowData.dev[key] ? JSON.parse(JSON.stringify(flowData.dev[key])) : flowData.dev[key]);
                    } else {
                        flowData.prod = JSON.parse(JSON.stringify(flowData.dev));
                    }
                    return { target: 'prod.' + flowId, data: flowData.prod };
                }).filter(e=>e);
                if (batch.length) {
                    await BatchUpdateFlowManagementFlow(batch).then(this.UpdateFlows);
                }
            }
        },
        FullCopyToOther: async function(key) {
            if (key === 'prod' && confirm('Do you want to save changes on DEVELOPMENT?\nThis action cannot be undone')) {
                const batch = [ ...this.selectedFlows ].map(flowId => {
                    const flowData = this.currentStatus.all[flowId];
                    if (!flowData.prod) return undefined;
                    flowData.dev = JSON.parse(JSON.stringify(flowData.prod));
                    return { target: 'dev.' + flowId, data: flowData.dev };
                }).filter(e=>e);
                if (batch.length) {
                    await BatchUpdateFlowManagementFlow(batch).then(this.UpdateFlows);
                }
            } else if (key === 'dev' && confirm('Do you want to save changes on PRODUCTION?\nThis action cannot be undone')) {
                const batch = [ ...this.selectedFlows ].map(flowId => {
                    const flowData = this.currentStatus.all[flowId];
                    if (!flowData.dev) return undefined;
                    flowData.prod = JSON.parse(JSON.stringify(flowData.dev));
                    return { target: 'prod.' + flowId, data: flowData.prod };
                }).filter(e=>e);
                if (batch.length) {
                    await BatchUpdateFlowManagementFlow(batch).then(this.UpdateFlows);
                }
            }
        },
    },
}
</script>
<style scoped>
.flows-list {
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}
.flow {
    background-color: hsl(210deg 100% 80%);
    border-radius: 4px;
    padding: 4px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    border: 2px solid transparent;
    &.selected {
        border-color: hsl(120deg 100% 30%);
        background-color: hsl(195deg 100% 80%);
    }
}
.stack {
    display: grid;
}
.stack > * {
    grid-area: 1/2/2/3;
    text-align: center;
}
</style>
